import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {Moment} from "moment";
import {IApiClient} from "../common/api.client";
import {QuestionDto} from "../common/webapicall";
import {AppContextStore} from "./app.context.store";
import {StoreBase} from "./store.base";

export class TeamSurveyStore extends StoreBase {
	constructor(private apiClient: IApiClient, private contextStore: AppContextStore) {
		super(apiClient);
		makeObservable(this);
	}

	@observable questions: QuestionDto[] = [];
	@observable canSubmitSurvey: boolean = false;
	@observable canAnswerWithCantSay: boolean = false;
	@observable completionDate?: Moment = null;
	@observable isCurrentUserTeamLeader: boolean = false;

	answersQueue: {[questionId: string]: AnswersQueue[]} = {};

	private headerValues: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

	@computed get getHeaderValues(): number[] {
		return this.headerValues;
	}

	@action
	async getTeamSurvey(pSurveyId: string) {
		const response = await this.apiClient.teamSurveyClient.getTeamSurvey(pSurveyId);
		runInAction(() => {
			if (response) {
				this.questions = response.questions;
				this.completionDate = response.completionDate;
				this.isCurrentUserTeamLeader = response.isCurrentUserTeamLeader;
				this.canAnswerWithCantSay = response.canAnswerWithCantSay;
				this.canSubmitSurvey = this.isEveryQuestionTouched && this.canEditSurveyForm;
			}
		});
	}

	@action
	handleAnswerChange(questionId: string, cannotAnswer: boolean, value?: number) {
		const question = this.questions.find(s => s.questionId === questionId);
		if (question && (question.value !== value || question.cannotAnswer !== cannotAnswer)) {
			question.value = value;
			question.cannotAnswer = cannotAnswer;
			question.untouched = !cannotAnswer && !value;
			this.questions = [...this.questions];

			// add new item to queue
			const newQueue = {value, cantSay: cannotAnswer} as AnswersQueue;
			if (this.answersQueue[questionId]) {
				this.answersQueue[questionId].forEach(aq => {
					clearTimeout(aq.timeOut);
					aq.timeOut = undefined;
				});
				this.answersQueue[questionId] = [...this.answersQueue[questionId].filter(aq => aq.timeOut !== undefined), newQueue];
			} else {
				this.answersQueue[questionId] = [newQueue];
			}
		}
	}

	@action
	async saveAnswer(questionId: string) {
		const pSurveyId = this.contextStore.contextModel.teamModel.loggedInUserParticipantSurveyId;
		if (pSurveyId) {
			const question = this.questions.find(q => q.questionId === questionId);
			if (question) {
				let queue = this.answersQueue[questionId];

				if (queue) {
					let lastQueue = queue[queue.length - 1];
					this.canSubmitSurvey = false;
					const timeOut = setTimeout(async () => {
						if (lastQueue.value === question.value && lastQueue.cantSay === question.cannotAnswer) {
							const canSubmitParticipant = await this.apiClient.teamSurveyClient.saveParticipantSurveyAnswer(
								pSurveyId,
								{
									questionId: questionId,
									value: question.value,
									cannotAnswer: question.cannotAnswer,
								},
							);
							runInAction(() => {
								this.canSubmitSurvey = canSubmitParticipant && this.isEveryQuestionTouched;
							});
						}
					}, 500);
					this.answersQueue[questionId] = [
						...queue.filter(aq => aq.timeOut !== undefined),
						{
							...lastQueue,
							timeOut: timeOut,
						},
					];
				}
			}
		}
	}

	@computed get untouchedQuestionCount(): number {
		if (!this.questions) return 0;
		return this.questions.filter(q => q.untouched).length;
	}

	@computed get isEveryQuestionTouched(): boolean {
		return !this.questions.some(q => q.untouched);
	}

	@computed get canEditSurveyForm(): boolean {
		return this.contextStore.contextModel.progress.isSurveyRunning && !this.contextStore.contextModel.progress.isDiagnosticSubmitted;
	}

	async submitDiagnostic() {
		const pSurveyId = this.contextStore.contextModel.teamModel.loggedInUserParticipantSurveyId;
		if (pSurveyId) {
			await this.apiClient.teamSurveyClient.submitParticipantSurvey(pSurveyId);
		}
	}
}

interface AnswersQueue {
	value?: number;
	cantSay: boolean;
	timeOut: NodeJS.Timeout;
}
